<template>
  <div class="profile">
    <p style='font-weight: 700;font-size: 18px;margin-top: 10%;margin-bottom: 5%;'>Профиль</p>
    <div class='userBlock'>
      <p style='font-size:36px;'>{{ user.emoji }}</p>
      <div class='userBlockText'>
        <div class='userLogin'>
          <p style='font-weight: 700;font-size: 18px;margin-right:5px;'>{{ user.login }}</p>
          <img v-if='user.verification' src='../assets/verification.svg'/>
        </div>
        <p style='font-size: 14px;'>id: <span style='color:#727272;'>41222552</span></p>
      </div>
    </div>
    <div class='profileBlock'>
      <p>Статус верификации</p>
      <p v-if='user.verification' style='font-weight: 700; color:#007BFF;'>пройдена</p>
      <p v-else>не пройдена</p>
    </div>
    <div class='profileBlock'>
      <p>Всего сделок</p>
      <p>{{ win + lose }}</p>
    </div>
    <div class='profileBlock'>
      <p>Прибыльные сделки</p>
      <p>{{ win }}</p>
    </div>
    <div class='profileBlock'>
      <p>Убыточные сделки</p>
      <p>{{ lose }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ProfileComponent',
  data() {
    return {
      active: "profile",
      user: {},
      win: " ",
      lose: " "
    }
  },
  methods: {
    getStats() {
      axios.get('https://jsbros.codes/api/bet/stats', {
        headers: {
          Authorization: "Bearer " + this.$store.state.user.token
        }
      })
      .then(res => {
        console.log(res)
        this.win = res.data.result.win
        this.lose = res.data.result.lose
      })
    }
  },
  mounted() {
    this.user = this.$store.getters.getUserData
    console.log(this.user)
    this.getStats()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.profile {
  height:100vh;
  width: 20vw;
  background:black;
  /* position: absolute; */
  border-left: 1px solid rgba(77, 77, 77, 0.452);
  border-right: 1px solid rgba(77, 77, 77, 0.452);
  /* left:6%; */
  text-align: left;
  padding: 20px;
}
.userBlock {
  border-radius:10px;
  background-color: #0B0B0B;
  padding: 25px;
  display: flex;
  align-items: center;
  margin-bottom: 5%;
}
.profileBlock {
  border-radius:10px;
  background-color: #0B0B0B;
  padding: 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}
.profileBlock p:nth-child(1) {
  font-size: 18px;
}
.profileBlock p:nth-child(2) {
  font-weight: 700;
  color:#5F5F5F; 
  font-size: 16px;
}
.userLogin {
  display: flex;
  align-items: center;
}
.userBlockText {
  margin-left:10px;
}
</style>
