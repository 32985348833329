<template>
  <div class="notifications">
    <p style='font-weight: 700;font-size: 18px;margin-top: 10%;margin-bottom: 5%;'>Уведомления</p>
    <p></p>
    <div class="notificationsList">
      <div class="notification" v-for="notify in notifications" v-bind:key="notify">
        <img src="../assets/successNotify.svg" v-if="notify.type == 'PAYMENT'"/>
        <img src="../assets/errorNotify.svg" v-if="notify.type == 'ERROR'"/>
        <img src="../assets/verifyNotify.svg" v-if="notify.type == 'VERIFICATION'"/>
        <img src="../assets/infoNotify.svg" v-if="notify.type == 'TEXT'"/>
        <div class="notifyMessage">
          <p  v-if="notify.type == 'PAYMENT'"></p>
          <p  v-if="notify.type == 'ERROR'"></p>
          <p  v-if="notify.type == 'VERIFICATION'">Верификация</p>
          <p  v-if="notify.type == 'TEXT'">Информация</p>
          <p>{{notify.message}}</p>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NotificationsComponent',
  data() {
    return {
      active: "profile",
      user: {},
      notifications: []
    }
  },
  methods: {
    loadNotifications() {
      axios.get('https://jsbros.codes/api/notification', {
        params: {
          count: 20
        },
        headers: {
          Authorization: 'Bearer ' + this.user.token
        }
      })
      .then(res => {
        console.log(res)
        this.notifications = res.data.result.data
      })
    }
  },
  mounted() {
    this.user = this.$store.getters.getUserData,
    this.loadNotifications()
  }
}
</script>

<style scoped>
.notifications {
  height:100vh;
  width: 20vw;
  background:black;
  border-left: 1px solid rgba(77, 77, 77, 0.452);
  border-right: 1px solid rgba(77, 77, 77, 0.452);
  text-align: left;
  padding: 20px;
  scrollbar-color: #ffffff #000000;
  scrollbar-width: none;
  overflow: auto;

}
.notificationsList {
  display: grid;
  gap:20px;
  
}
.notification {
  background-color: #0F0F0F;
  border-radius: 8px;
  min-height: 10vh;
  display: flex;
  align-items: center;
  padding:15px;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  overflow-wrap: anywhere;
}
.notification p:nth-child(1) {
  font-weight: 700;
  font-size: 18px;
}
.notification p:nth-child(2) {
  font-size: 16px;
}
.notification img {
  width:25px;
  margin-right: 15px;
}
</style>
