<template>
  <div class='bodyDashboard'>
    
    <div class='components'>
      <SideBar @newActive='changeSideActive' ref="hideSide"/>
      <ProfileComponent v-if='active=="profile"'/>
      <WalletComponent v-if='active=="wallet"'/>
      <ActivesComponent v-if='active=="actives"' @onChangeCurrency="changeCurrency"/>
      <FAQComponent v-if='active=="faq"'/>
      <NotificationsComponent v-if='active=="notifications"'/>
    </div>
    
    <div class='workSpace'>
      <DashBoard ref="currency"/>
    </div>
  </div>
</template>
<script>
import ActivesComponent from '@/components/ActivesComponent.vue'
import FAQComponent from '@/components/FAQComponent.vue'
import ProfileComponent from '@/components/ProfileComponent.vue'
import SideBar from '@/components/SideBar.vue'
import WalletComponent from '@/components/WalletComponent.vue'
import DashBoard from './DashBoard.vue'
import NotificationsComponent from '@/components/NotificationsComponent.vue'
import {ref} from 'vue'
// @ is an alias to /src

export default {
  name: 'DashboardPage',
  data() {
    return {
      user: "",
      password: "",
      active: "home",
      currency: ref(null),
      showPassword: false
    }
  },
  components: {
    SideBar,
    ProfileComponent,
    DashBoard,
    WalletComponent,
    ActivesComponent,
    FAQComponent,
    NotificationsComponent
  },
  methods: {
    showPasswordButton() {
      this.showPassword = !this.showPassword
      let pass = document.getElementById('password')
      if (this.showPassword) pass.type = 'text'
      else pass.type='password'
    },
    changeSideActive(data) {
      this.active = data.sideActive
    },
    changeCurrency(data) {
      console.log(data)
      this.$refs.currency.changeCurrency(data)
    }
  }
}
</script>
<style scoped>
.bodyDashboard {
  position: relative;
  background: url('../assets/background.png');
  background-size: 100%;
  background-repeat: no-repeat;
  width:100vw;
  height:100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.workSpace {
  width:100%;
  height:100vh;
}
.components {
  display: flex;
  position: absolute;
  left: 0;
  z-index: 9999;
}
</style>
