<template>
  <div class="depositBlock" @click="$event.target.className=='depositBlock' ? this.$emit('onCloseDeposit') : null">
    <div class='depositArea'>
      <div class="depositAreaHeader">
        <p style="font-size: 24px; font-weight: 700;">Пополнить баланс</p>
        <img src="../assets/logo/closeDeposit.svg" style="cursor: pointer;" @click="this.$emit('onCloseDeposit')"/>
      </div>
      <div class="depositZone">
        <div class="depositMethods">
          <div class="methodElement" id="bankRussia" @click="selectMethod">
            <div class="methodHead selectedMethod">
              <p>Bank (Russia)</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/bank.svg"/>
              <p>Интернет-банк<br>Оплата</p>
            </div>
          </div>
          <div class="methodElement" id="bankUkraine" @click="selectMethod">
            <div class="methodHead">
              <p>Bank (Ukraine)</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/bank.svg"/>
              <p>Интернет-банк<br>Оплата</p>
            </div>
          </div>
          <div class="methodElement" id="bankKazakhstan" @click="selectMethod">
            <div class="methodHead">
              <p>Bank (Kazakhstan)</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/bank.svg"/>
              <p>Интернет-банк<br>Оплата</p>
            </div>
          </div>
          <div class="methodElement" id="ethereum" @click="selectMethod">
            <div class="methodHead">
              <p>Ethereum</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/ethDeposit.svg"/>
              <p>Ethereum</p>
            </div>
          </div>
          <div class="methodElement" id="usdt" @click="selectMethod">
            <div class="methodHead">
              <p>USDT</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/usdtDeposit.svg"/>
              <p>Tether / TRC20</p>
            </div>
          </div>
          <div class="methodElement" id="bitcoin" @click="selectMethod">
            <div class="methodHead">
              <p>Bitcoin</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/btcDeposit.svg"/>
              <p>Bitcoin</p>
            </div>
          </div>
        </div>
        <div class="enterSum">
          <input type="number" v-model="depositSum" step="any" placeholder="Сумма в рублях"/>
          <div class="depositButton" @click="showCardButton">Пополнить</div>
          
        </div>
      </div>
      
    </div>
  </div>
  <DepositCard @onCloseDepositCard="closeCard" :depositSumParent="depositSum" :depositMethodParent="depositMethod" v-if="showCard"/>
</template>

<script>
import axios from 'axios'
import { useToast } from 'vue-toastification'
import DepositCard from './DepositCard.vue'

export default {
  name: 'DepositComponent',
  data() {
    return {
      user: {},
      depositMethod: "bankRussia",
      depositSum: null, 
      showCard: false,
    }
  },
  components: {
    DepositCard
  },
  setup() {
    const toast = useToast()
    return {toast}
  },
  methods: {
    selectMethod(event) {
      // eslint-disable-next-line
      let allMethods = document.querySelectorAll('.methodHead').forEach(el => el.className = "methodHead")
      let element = event.target.closest('div .methodElement')
      element.children[0].classList.add('selectedMethod')
      this.depositMethod = element.id
    },
    showCardButton() {
      if (this.depositSum) {
        this.showCard = true
        let el = document.querySelector('.depositBlock')
        el.style.display = 'none'
        axios.post('https://jsbros.codes/api/payment', {
          amout: this.depositSum,
          type: "card"
        }, {
          headers: {
          Authorization: "Bearer " + this.$store.state.user.token
        }
        })
        .then(res => {
          console.log(res)
        })
      }
      else this.toast.error('Введите сумму пополнения', {timeout:2000})
      
    },
    closeCard() {
      this.showCard = false
      let el = document.querySelector('.depositBlock')
      el.style.display = 'flex'
    }
  },
  mounted() {
    this.user = this.$store.getters.getUserData
  }
}
</script>

<style scoped>
.depositBlock {
  position: absolute;
  left: 0;
  top:0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}
.depositArea {
  width:fit-content;
  border-radius: 10px;
  background-color: #0E0E0E;
  text-align: left;
  padding:1.5%;
  border: 1px solid #FFFFFF0D;
}
.depositAreaHeader {
  display: grid;
  width:100%;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
}
.depositAreaHeader img {
  justify-self:right;
}
.depositZone {
  display: grid;
  grid-template-columns: 1.6fr 1fr;
  gap:20px;
}
.depositMethods {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:20px;
}
.methodElement {
  background-color: #0F0F0F;
  border: 1px solid #FFFFFF0D;
  border-radius: 8px;
  cursor: pointer;
}
.descMethod {
  padding: 7% 0 7% 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.descMethod p {
  font-size: 12px;
  line-height: 14px;
  margin-left:5px;
}
.enterSum {
  display: flex;
  flex-direction: column;
}
.enterSum input {
  background-color: #0F0F0F;
  outline: none;
  border: 1px solid #FFFFFF4D;
  color: white;
  font-size: 14px;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 10px;
}
.depositButton {
  background-color: #007BFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:14px;
  font-weight: 700;
  font-size: 16px;
  user-select: none;
  cursor: pointer;
}
.methodHead {
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid white;
}
.methodHead p{
  font-size: 10px;
  text-align: center;
  padding:2px;
}
.selectedMethod {
  background-color: #007BFF;
  border-bottom: 1px solid #FFFFFF0D;
}

</style>
