<template>
  <div class='dashboard'>
    <header class='dashboardHeader'>
      <div class='selectModeChart'>
        <p id='1m' @click='setTimeFilter'>1m</p>
        <p id='30m' @click='setTimeFilter'>30m</p>
        <p id='1h' class='timeFilter' @click='setTimeFilter'>1h</p>
      </div>
      <div class='headerRight'>
        <div class='deposit' @click="deposit = true"><p>Пополнить</p></div>
        <DepositComponent v-if="deposit" @onCloseDeposit="closeDeposit" style="z-index: 10000;"/>
        <div class='balance'>
          <p style='margin-right: 5px;'>Баланс</p>
          <p style='color: #727272;'>{{this.$store.getters.getUserData.balance}}₽</p>
        </div>
      </div>
		</header>
    <div class='chart'>
      <div class='titleChart'>
        <div class='firstTitleChart'>
          <p>{{ currency }}/USD</p>
          <p>•</p>
          <p>{{ timeFilter }}</p>
          <p>•</p>
          <p>Uniswap</p>
        </div>
        <div class='secondTitleChart'>
          <p class='typeHeadCandle'>O</p><p :class='lastCandle[5] + "HeadCandle"'>{{ lastCandle[1].toFixed(3) }}</p>
          <p class='typeHeadCandle'>H</p><p :class='lastCandle[5] + "HeadCandle"'>{{ lastCandle[2].toFixed(3) }}</p>
          <p class='typeHeadCandle'>L</p><p :class='lastCandle[5] + "HeadCandle"'>{{ lastCandle[3].toFixed(3) }}</p>
          <p class='typeHeadCandle'>C</p><p :class='lastCandle[5] + "HeadCandle"'>{{ lastCandle[4].toFixed(3) }}</p>
        </div>
      </div>
      <highcharts class="highcharts-dark" style='height:62vh;' :options="chartOptions" :constructor-type="'stockChart'"></highcharts>
    </div>
  </div>
  <div class='bottomChart'>
    <div class='bottomBet'>
      <input type='number' placeholder='Сумма в рублях' v-model='betSum'/>
      <div class='bottomButtons'>
        <div class='bottomChangeButtons' @click="changeSum"><p>+</p></div>
        <div class='bottomChangeButtons' @click="changeSum"><p>—</p></div>
      </div>
    </div>
    <div class='bottomBet'>
      <input type='number' placeholder='Время сделки в секундах' v-model='betTime'/>
      <div class='bottomButtons'>
        <div class='bottomChangeButtons' @click="changeTime"><p>+</p></div>
        <div class='bottomChangeButtons' @click="changeTime"><p>—</p></div>
      </div>
    </div>
    <div class='betButtons'>
      <div class='betUpButton' id='up' @click='bet'>
        <p>Вверх</p>
      </div>
      <div class='betDownButton' id='down' @click='bet'>
        <p>Вниз</p>
      </div>
    </div>
    <div class='possibleIncome'>
      <p @click='this.chart.series[0].addPoint([1728805265000,66,65,63,62], false)'>Возможный доход</p>
      <p style='color: #727272;'>{{ betSum }}₽</p>
    </div>
  </div>
</template>
<script>
import DepositComponent from '@/components/DepositComponent.vue'
import axios from 'axios'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
import { useToast } from 'vue-toastification'
stockInit(Highcharts)
export default {
  name: 'DashBoard',
  components: {
    DepositComponent
  },
  data() {
    return {
      betSum: null,
      betTime: null,
      timeFilter: "1h",
      candlesInterval: null,
      deposit: false,
      skipCandle: false,
      lastCandle: [0,0,0,0,0, "green"],
      currency: this.$store.getters.getCurrency,
      dataSet: [],
      balance: this.$store.getters.getUserData.balance,
      chartOptions: {
          height: null,
          chart: {
            backgroundColor: "transparent",
            panning: {
              enabled: true,
              type: 'x'
            },
            events: {
            load: (function(self) {
              return function() {
                self.chart = this; // saving chart reference in the component
              };
            })(this)
          }
          },
          rangeSelector : {
              enabled : false
          },
          navigator: {
            enabled: false
          },
          scrollbar: { enabled: false },
          subtitle: {
              style: {
                  color: '#ff0000',
                  font: 'bold 12px "Trebuchet MS", Verdana, sans-serif'
              }
          },
          plotOptions: {
              candlestick: {
                  color: '#FF3B30',
                  lineColor: '#FF3B30',
                  upColor: '#00C896',
                  upLineColor: '#00C896'
              }
          },
          yAxis: {
              gridLineWidth: 0,
              labels: {
                step: 1,
                format: '${text}',
                align: 'left',
                style: {
                  color: "#C4C4C4",
                  font: '700 16px "Proxima Nova Regular", sans-serif',
                }
              },
          },
          xAxis: {
              overscroll: '30px',
              type: 'datetime',
              labels: {
                style: {
                  color: "#C4C4C4",
                  font: '700 16px "Proxima Nova Regular", sans-serif',
                }
              },
          },
          series: [
          {
            type: 'candlestick',
            name: 'Information',
            data: [[0,0,0,0,0]],
            tooltip: {
              valueDecimals: 6
            }
          }]
        }
    }
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  mounted() {
    this.removeWatermark()
    this.loadCandles(this.timeFilter, this.currency)
  },
  methods: {
    changeSum(event) {
      let button = event.target.closest('div')
      if (button.children[0].innerHTML == "+") this.betSum += 100
      else if (button.children[0].innerHTML == "—" && this.betSum - 100 > 0) this.betSum -= 100
    },
    changeTime(event) {
      let button = event.target.closest('div')
      if (button.children[0].innerHTML == "+") this.betTime += 10
      else if (button.children[0].innerHTML == "—" && this.betTime - 10 > 0) this.betTime -= 10
    },
    showPasswordButton() {
      this.showPassword = !this.showPassword
      let pass = document.getElementById('password')
      if (this.showPassword) pass.type = 'text'
      else pass.type='password'
    },
    changeCurrency() {
      this.currency = this.$store.getters.getCurrency
      this.loadCandles(this.timeFilter, this.currency)
    },
    bet(event) {
      if (this.skipCandle) this.toast.error("Ожидайте конца ставки", {timeout: 2000})
      else {
        let betMode = event.target.closest('div')
        betMode = betMode.id
        axios.post('https://jsbros.codes/api/bet/', 
          { 
              amount: this.betSum,
              time: this.betTime,
              currency: this.$store.getters.getCurrency,
              direction: betMode,
          }, 
          {
            headers: {
                Authorization: 'Bearer ' + this.$store.state.user.token
              }
          })
        .then(res => {
          let niceCandle = res.data.result
          let niceCandleArray = [niceCandle.timestamp, niceCandle.open, niceCandle.high, niceCandle.low, niceCandle.close]
          this.toast.info("Ожидаем конца ставки...", {timeout: this.betTime*1000})
          this.skipCandle = true
          setTimeout(() => {
            this.chartOptions.series[0].data.push(niceCandleArray)
            this.skipCandle = false
            axios.get('https://jsbros.codes/api/users/me', {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.user.token
            }
            })
            .then(res => {
              if (this.$store.getters.getUserData.balance < res.data.result.balance) this.toast.success(`Ваш доход составил ${this.betSum}₽`)
              else this.toast.error(`Ваш доход проигрыш ${this.betSum}₽`)
              this.$store.commit('setUserData', res.data.result)
            })
          }, this.betTime * 1000)

          })
          .catch(err => {
            console.log(err.response.data.message)
            this.toast.error('Время ставки должно быть не менее 10 секунд.', {timeout: 3000})
          })
      }
      
    },
    removeWatermark() {
        let t = document.querySelector('.highcharts-credits')
        t.remove()
      },
    loadCandles(time, currency) {
      clearInterval(this.candlesInterval)
      this.chartOptions.series[0].data = []
      this.timeFilter = time
      let now = Math.round(new Date())
      let hourAgo = new Date()
      let hourAhead = ((Math.round(now / 1000)) + 3600) * 1000
      switch(time) {
        case '1h':
          hourAgo = ((Math.round(now / 1000)) - 3600) * 1000
          break
        case '30m':
          hourAgo = ((Math.round(now / 1000)) - 1800) * 1000
          break
        case '1m':
          hourAgo = ((Math.round(now / 1000)) - 60) * 1000
          break
      }
      console.log(hourAgo,now,hourAhead)
        axios.get(`https://jsbros.codes/api/bet/candles/${currency}`, 
        {
          params : 
          {
            start: hourAgo.toString(),
            end: hourAhead.toString(),
          },
          headers: {
              Authorization: 'Bearer ' + this.$store.state.user.token
            }
        })
        .then(res => {
          let temp = {}
          let firstArray = []
          if (time == "1h") {
            firstArray = res.data.result.slice(0,res.data.result.length/2)
            this.dataSet = res.data.result.slice(res.data.result.length/2)
          }
          else if (time == "30m") {
            firstArray = res.data.result.slice(0,res.data.result.length/3)
            this.dataSet = res.data.result.slice(res.data.result.length/3)
          }
          else if (time == "1m") {
            firstArray = res.data.result.slice(0,res.data.result.length/60)
            this.dataSet = res.data.result.slice(res.data.result.length/60)

          }
          
          for(let i = 0;i<firstArray.length; i++){
            // console.log(res.data.result[i])
            temp = [
              res.data.result[i].timestamp,
              res.data.result[i].open,
              res.data.result[i].high,
              res.data.result[i].low,
              res.data.result[i].close
            ]
            this.chartOptions.series[0].data.push(temp)
            // this.chart.series[0].addPoint(temp, false)
            this.lastCandle = temp
            if (this.chartOptions.series[0].data[this.chartOptions.series[0].data.length-1][4] > this.lastCandle[4]) this.lastCandle[5] = "green"
            else this.lastCandle[5] = "red"
          }
          let i = 0
          let tempArr = []
          this.candlesInterval = setInterval(() => {
            if (this.skipCandle) i++
            else {
              tempArr = [this.dataSet[i].timestamp, this.dataSet[i].open, this.dataSet[i].high, this.dataSet[i].low, this.dataSet[i].close]
              this.chartOptions.series[0].data.push(tempArr)
              this.lastCandle = tempArr
              if (this.chartOptions.series[0].data[this.chartOptions.series[0].data.length-2][4] < this.lastCandle[4]) this.lastCandle[5] = "green"
              else this.lastCandle[5] = "red"
              // this.chart.xAxis[0].setExtremes();
              // this.chartOptions.series[0].data.shift()
              i++
              if (i == this.dataSet.length) clearInterval(this.candlesInterval)
            }
            
          }, 5000);
        })
      },
      setTimeFilter(event) {
        let t = document.querySelector('.selectModeChart')
        t = t.children
        for (let el of t) {
          el.className = ""
        }
        let pick = document.getElementById(event.target.id)
        pick.className = "timeFilter"
        this.chartOptions.series[0].data = []
        this.loadCandles(event.target.id, this.currency)
    },
    closeDeposit() {
      this.deposit = false
    }
  }
}
</script>
<style scoped>
.dashboard {
	width:70%;
	margin: 0 auto;
  margin-top:20px;
	border-radius: 10px;
  border: 1px solid #FFFFFF0D;
	background: #C4C4C405;
  padding: 2%;
  height: 80%;
  margin-bottom:10px;
}
.dashboardHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width:100%;
  align-items: center;
}
.chart {
  width:100%;
  margin: 0 auto;
  background-image: url('../assets/chartBackground.png');
  background-size: 100%;
  background-position: -5vw 0vh;
  background-repeat: no-repeat;
}
.bottomChart {
  width: 70%;
  margin: 0 auto;
	border-radius: 10px;
  border: 1px solid #FFFFFF0D;
	background: #C4C4C405;
  padding: 1%;
  height: fit-content;
  display: grid;
  align-items: center;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 0.7fr;
  gap:20px;
}
.bottomBet {
  display: flex;
}
.bottomButtons {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap:10px;
}
.bottomChangeButtons {
  background-color: #090909;
  border: 1px solid #FFFFFF0D;
  border-radius: 8px;
  padding: 3px 30px 3px 30px;
  text-align: center;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 700;
  height: 25px;
  cursor: pointer;
  user-select: none;
}
.bottomChangeButtons:nth-child(1) {
  font-size: 18px;
  font-weight: 700;
}
.bottomBet input {
  background-color: #0F0F0F;
  border: 1px solid #0F0F0F;
  color: white;
  outline: none;
  border-radius: 8px;
  width: 100%;
  padding: 16px 14px 16px 14px;
  font-size: 16px;
}
.bottomBet input::placeholder {
  color: #727272;
}
.betButtons {
  display: flex;
  gap: 20px;
  font-size: 18px;
  font-weight: 700;
  height: 100%;
}
.betUpButton {
  background-color: #00C896;
  border-radius: 8px;
  padding: 16px 14px 16px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  color: #000000;
  cursor: pointer;
}
.betDownButton {
  background-color: #ED3232;
  border-radius: 8px;
  padding: 16px 14px 16px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
}
.possibleIncome {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 16px;
}
.selectModeChart {
  display: flex;
  font-size: 16px;
  font-weight: 700;
  gap:20px;
}
.selectModeChart p {
  cursor: pointer;
}
.headerRight {
  justify-self: right;
  display: flex;
}
.titleChart {
  margin-top: 2%;
  display: grid;
  grid-template-columns: 0.7fr 1fr;
}
.firstTitleChart {
  display: flex;
  font-weight: 700;
  gap:25px;
}
.secondTitleChart {
  display: flex;
  gap: 20px;
}
.secondTitleChart p{
  font-size: 18px;
}
.typeHeadCandle{
  font-weight: 700;
}
.timeFilter {
  color: #2F80ED;
}
.deposit {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 16px 28px 16px 28px;
  color: black;
  font-weight: 700;
  font-size: 18px;
  margin-right: 15px;
  cursor: pointer;
}
.greenHeadCandle {
  color: #00C896;
}
.redHeadCandle {
  color: #EB5757;
}
.balance {
  display: flex;
  min-width: 10vw;
  background: #0F0F0F;
  border: 1px solid #FFFFFF0D;
  border-radius: 8px;
  padding: 17px 28px 17px 28px;
  color: rgb(255, 255, 255);
  font-size: 18px;
}
.apexcharts-tooltip {
    background: #f3f3f3;
    color: orange;
  }
</style>
