<template>
  <div class="withdrawBlock" @click="$event.target.className=='withdrawBlock' ? this.$emit('onCloseWithdraw') : null">
    <div class='withdrawArea'>
      <div class="withdrawAreaHeader">
        <p style="font-size: 24px; font-weight: 700;">Вывести средства</p>
        <img src="../assets/logo/closeDeposit.svg" style="cursor: pointer;" @click="this.$emit('onClosewithdraw')"/>
      </div>
      <div class="withdrawZone">
        <div class="withdrawMethods">
          <div class="methodElement" id="bankRussia" @click="selectMethod">
            <div class="methodHead selectedMethod">
              <p>Bank (Russia)</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/bank.svg"/>
              <p>Интернет-банк<br>Оплата</p>
            </div>
          </div>
          <div class="methodElement" id="bankUkraine" @click="selectMethod">
            <div class="methodHead">
              <p>Bank (Ukraine)</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/bank.svg"/>
              <p>Интернет-банк<br>Оплата</p>
            </div>
          </div>
          <div class="methodElement" id="bankKazakhstan" @click="selectMethod">
            <div class="methodHead">
              <p>Bank (Kazakhstan)</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/bank.svg"/>
              <p>Интернет-банк<br>Оплата</p>
            </div>
          </div>
          <div class="methodElement" id="ethereum" @click="selectMethod">
            <div class="methodHead">
              <p>Ethereum</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/ethDeposit.svg"/>
              <p>Ethereum</p>
            </div>
          </div>
          <div class="methodElement" id="usdt" @click="selectMethod">
            <div class="methodHead">
              <p>USDT</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/usdtDeposit.svg"/>
              <p>Tether / TRC20</p>
            </div>
          </div>
          <div class="methodElement" id="bitcoin" @click="selectMethod">
            <div class="methodHead">
              <p>Bitcoin</p>
            </div>
            <div class="descMethod">
              <img src="../assets/logo/btcDeposit.svg"/>
              <p>Bitcoin</p>
            </div>
          </div>
        </div>
        <div class="enterSum">
          <input type="number" v-model="withdrawSum" step="any" placeholder="Сумма в рублях" required/>
          <input type="text" v-model="invoiceNumber" placeholder="Номер счета" required/>
          <div class="withdrawButton">Вывести</div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'withdrawComponent',
  data() {
    return {
      user: {},
      withdrawMethod: "bankRussia",
      withdrawSum: null,
      invoiceNumber: null
    }
  },
  methods: {
    selectMethod(event) {
      // eslint-disable-next-line
      let allMethods = document.querySelectorAll('.methodHead').forEach(el => el.className = "methodHead")
      let element = event.target.closest('div .methodElement')
      element.children[0].classList.add('selectedMethod')
      this.withdrawMethod = element.id
    }
  },
  mounted() {
    this.user = this.$store.getters.getUserData
  }
}
</script>

<style scoped>
.withdrawBlock {
  position: absolute;
  left: 0;
  top:0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.withdrawArea {
  width:fit-content;
  border-radius: 10px;
  background-color: #0E0E0E;
  text-align: left;
  padding:1.5%;
  border: 1px solid #FFFFFF0D;
}
.withdrawAreaHeader {
  display: grid;
  width:100%;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
}
.withdrawAreaHeader img {
  justify-self:right;
}
.withdrawZone {
  display: grid;
  grid-template-columns: 1.6fr 1fr;
  gap:20px;
}
.withdrawMethods {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:20px;
}
.methodElement {
  background-color: #0F0F0F;
  border: 1px solid #FFFFFF0D;
  border-radius: 8px;
  cursor: pointer;
}
.descMethod {
  padding: 7% 0 7% 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.descMethod p {
  font-size: 12px;
  line-height: 14px;
  margin-left:5px;
}
.enterSum {
  display: flex;
  flex-direction: column;
}
.enterSum input {
  background-color: #0F0F0F;
  outline: none;
  border: 1px solid #FFFFFF4D;
  color: white;
  font-size: 14px;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 10px;
}
.withdrawButton {
  background-color: #007BFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:14px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.methodHead {
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid white;
}
.methodHead p{
  font-size: 10px;
  text-align: center;
  padding:2px;
}
.selectedMethod {
  background-color: #007BFF;
  border-bottom: 1px solid #FFFFFF0D;
}

</style>
