<template>
  <div class="depositCardBlock" @click="$event.target.className == 'depositCardBlock' ? this.$emit('onCloseDepositCard') : null">
    <div class="depositCardArea">
      <div class="depositCardHead">
        <p style="font-size: 20px; font-weight: 700;">Пополнить баланс</p>
        <img src="../assets/logo/closeDeposit.svg" style="cursor: pointer;" @click="this.$emit('onCloseDepositCard')"/>
      </div>
      <div class="methodImg" v-if="depositMethod.slice(0,4) == 'bank'">
        <img src="../assets/logo/bank.svg"/>
        <p>Интернет-банк<br>Оплата ({{ depositMethod.slice(4) }})</p>
      </div>
      <div class="methodImg" v-if="depositMethod == 'ethereum'">
        <img src="../assets/logo/ethDeposit.svg"/>
        <p>{{depositMethod.charAt(0).toUpperCase() + depositMethod.slice(1)}}</p>
      </div>
      <div class="methodImg" v-if="depositMethod == 'usdt'">
        <img src="../assets/logo/usdtDeposit.svg"/>
        <p>{{depositMethod.toUpperCase()}}</p>
      </div>
      <div class="methodImg" v-if="depositMethod == 'bitcoin'">
        <img src="../assets/logo/btcDeposit.svg"/>
        <p>{{depositMethod.charAt(0).toUpperCase() + depositMethod.slice(1)}}</p>
      </div>
      <div class="depositInputs">
        <p>К оплате</p>
        <input type="text" class="depositInput" :value="depositSum" readonly/>
        <p>Банк перевода</p>
        <input type="text" class="depositInput" :value="depositSum" readonly/>
        <p>Номер карты</p>
        <input type="text" class="depositInput" :value="depositSum" readonly/>
        <p>Номер телефона</p>
        <input type="text" class="depositInput" :value="depositSum" readonly/>
        <div class="checkDeposit">
          Проверить платёж
        </div>
        <p>{{ checkDepositText }}</p>
      </div>
      
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'DepositComponent',
  data() {
    return {
      user: {},
      depositMethod: 'bankRussia',
      depositSum: null,
      checkDepositText: null
    }
  },
  props: ['depositSumParent', 'depositMethodParent'],
  methods: {
    
  },
  mounted() {
    this.user = this.$store.getters.getUserData
    this.depositSum = this.depositSumParent
    this.depositMethod = this.depositMethodParent
  }
}
</script>

<style scoped>
.depositCardBlock {
  position: absolute;
  top:0;
  left: 0;
  backdrop-filter: blur(5px);
  width:100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10001;
}
.depositCardArea {
  width:fit-content;
  border-radius: 10px;
  background-color: #0E0E0E;
  text-align: left;
  padding:1.5%;
  border: 1px solid #FFFFFF0D;
}
.depositCardHead {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.depositCardHead img{
  justify-self: right;
}
.methodImg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:17px;
  font-size: 19px;
}
.methodImg img{
  width:40px;
  margin-right: 5px;
}
.depositInputs {
  width:100%;
  padding:12px;
  padding-bottom: 20px;
  font-size: 14px;
}
.depositInput {
  width:100%;
  background-color: #0E0E0E;
  border: 1px solid #FFFFFF4D;
  outline: none;
  border-radius: 8px;
  padding:7px 15px 7px 15px;
  color: white;
  margin-bottom: 20px;
}
.checkDeposit {
  width:100%;
  background-color: #007BFF;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
}
</style>
