<template>
  <div class="faq">
    <p style='font-weight: 700;font-size: 18px;margin-top: 10%;margin-bottom: 5%;'>FAQ</p>
    <div class='questions'>
      <details>
          <summary>Как зарегистрироваться на платформе CryptoPulse?</summary>
          <p>Для регистрации на платформе необходимо нажать на кнопку "Регистрация" на главной странице и следовать инструкциям. Вам потребуется указать электронную почту, создать пароль и подтвердить учетную запись.</p>
        </details>
      <details>
        <summary>Какие криптовалютные пары поддерживаются для торговли опционами?</summary>
        <p>На CryptoPulse можно торговать парами криптовалют и USD, делая прогнозы на движение цен (вверх или вниз) в течение определенного времени.</p>
      </details>
      <details>
        <summary>Как использовать промокод для получения бонуса?</summary>
        <p>Промокод можно ввести при пополнении счета. После успешного ввода вы увидите уведомление о том, что промокод активирован, и бонус к пополнению будет автоматически добавлен на ваш счет.</p>
      </details>
      <details>
        <summary>Как я могу пополнить свой счет?</summary>
        <p>Для пополнения счета перейдите в раздел "Пополнение", выберите удобный способ и следуйте инструкциям. Поддерживаются различные методы оплаты, включая криптовалюты и банковские карты.</p>
      </details>
      <details>
        <summary>Как вывести средства с платформы?</summary>
        <p>Для вывода средств необходимо перейти в раздел "Вывод", выбрать предпочтительный способ и ввести данные для вывода. Средства будут переведены после успешной обработки запроса.</p>
      </details>
      <details>
        <summary>Как проходит верификация аккаунта?</summary>
        <p>Для верификации аккаунта необходимо предоставить необходимые документы в личном кабинете. О необходимости пройти верификацию вы получите уведомление.</p>
      </details>
      <details>
        <summary>Какие уведомления я буду получать?</summary>
        <p>Вы будете получать уведомления о пополнении счета, успешном или неудачном выводе средств, а также текстовые уведомления и напоминания о необходимости верификации.</p>
      </details>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQComponent',
  data() {
    return {
      active: "profile",
      user: {}
    }
  },
  methods: {
    
  },
  mounted() {
    this.user = this.$store.getters.getUserData
  }
}
</script>

<style scoped>
.faq {
  height:100vh;
  width: 20vw;
  background:black;
  border-left: 1px solid rgba(77, 77, 77, 0.452);
  border-right: 1px solid rgba(77, 77, 77, 0.452);
  text-align: left;
  padding: 20px;
  scrollbar-color: #ffffff #000000;
  scrollbar-width: none;
  overflow: auto;
}

.questions details {
  background-color: #0F0F0F;
  padding: 16px 14px 16px 14px;
  border-radius: 8px;
  margin-bottom: 15px;
}
.mostQuestionsBlock {
  width: 60%;
  margin: 0 auto;
  text-align: left;
  font-size:22px;
  color: #FFFFFF;
}

.questions summary {
  cursor:pointer;
  user-select: none;
  display: grid;
  align-items: center;
  grid-template-columns: 99fr 1fr;
}
.questions details p {
  margin-top:10px;
  color: #FFFFFF80;
  font-size: 14px;
}
summary::after {
    content: "+";
    color: #fff;
    font-size: 1.5em;
    font-family: 'Proxima Nova Light';
    font-weight: 100;
    padding: 0;
    text-align: center;
    width: 20px;
    justify-self: right;
    transition: 0.2s;

}
details[open] > summary::after {
  transform: rotate(45deg);
  transition: 0.2s;
}
summary::marker {
    content: "";
}
</style>
